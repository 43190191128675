import React, { useState } from 'react'
import './Contact.css'
import jigsaw from './jigsaw.png'
import { GetTranlatedValue } from '../Data/LanguageContext'
import { ContactUsPageData } from '../Data/ContactUsData'
import { useForm } from 'react-hook-form'
import axios from 'axios'
const Contact = () => {
    const [errors, setErrors] = useState("");
    const [success, setSuccess] = useState("");
    const [request, setRequest] = useState(false);
    const { register, handleSubmit } = useForm({
        default: {
            event: "PUBLIC_USER_REQUESTED_SUPPORT",
            data:{
                full_name:"",
                mobile:"",
                email:"",
                message:"",
            }
        }
    })
    const PushReuquest = async (data) => {
        try {
            setErrors("");
            setSuccess("");
            setRequest(true);
            const result = await axios.post("/api/v1/push/public", data);
            if (result.status === 200) {
                if (result.data?.status) {
                    setSuccess(result?.data?.message);
                } else {
                    setErrors(result?.data?.message);
                }
            }
        } catch (err) {
            setErrors("Opps,Something went wrong!");
        } finally {
            setRequest(false);
        }
    }
    return (
        <div className='contact-container '>
            <div className='contact-back d-flex justify-content-center'>
                <div className='d-flex flex-column '>
                    <h1 className='text-center'>
                        {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactusheading)}
                    </h1>
                    <p className='d-flex justify-content-center align-items-center'>
                        {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactusheading2)}
                    </p>
                </div>
            </div>
            <div className='contact-main-container py-5'>
                <div className='row contact-row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 p-3 contact-about-content'>
                        <div className='contact-h3 d-flex justify-content-start'>
                            <h3 className='d-flex justify-content-start'>  {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.teamcsp)}</h3>
                        </div>
                        <div className='contact-us-img-li'>
                            <div className='d-flex'>
                                <img src={jigsaw} alt='cont' />
                                <p>
                                    {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.filldetails)}
                                </p>
                            </div>
                        </div>

                        <div className='contact-us-img-li'>
                            <div className='d-flex'>
                                <img src={jigsaw} alt='cont' />
                                <p>
                                    {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.callus)}<span style={{ fontWeight: 600 }}>  {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactnumber)}</span>
                                    <span> {GetTranlatedValue(ContactUsPageData?.CONTACTUS?.contactnumbertext)}</span>
                                </p>
                            </div>
                        </div>

                        <div className='contact-us-img-li'>
                            <p>{GetTranlatedValue(ContactUsPageData?.CONTACTUS?.mailus)} <a href='mailto:support@csp.in' className='text-primary'>{GetTranlatedValue(ContactUsPageData?.CONTACTUS?.mail)} </a>{GetTranlatedValue(ContactUsPageData?.CONTACTUS?.address)}</p></div>

                    </div>
                    <div className='contact-form col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center'>
                        <form className='form' onSubmit={handleSubmit(PushReuquest)}>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    {
                                        errors && errors!=="" && (
                                            <div class="alert alert-warning alert-dismissible fade show" role="alert" style={{width:"450px"}}>
                                            {errors}
                                        </div>
                                        )
                                    }
                                    {
                                        success && success!=="" && (
                                            <div class="alert alert-success alert-dismissible fade show" role="alert" style={{width:"450px"}}>
                                            {success}
                                        </div>
                                        )
                                    }
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="fname" className="form-label">Full Name</label>
                                    <input type="hidden" className="form-control" {...register("event")} value={"PUBLIC_USER_REQUESTED_SUPPORT"} />
                                    <input type="text" className="form-control" {...register("data.full_name", { required: "please enter full name" })} />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" {...register("data.email", { "required": "please enter mail" })} />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="mobile" className="form-label">Mobile Number</label>
                                    <input type="phone" className="form-control" id="mobile" name="mobile" {...register("data.mobile", { required: "please enter mobile" })} />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label for="cname" className="form-label">Message</label>
                                    <textarea type="text" className="form-control" rows={4} id="cname" name="companyname" {...register("data.message", { required: "please enter message" })} />
                                </div>
                                <div className='contact-btn-mobile'>
                                    <button className='btn text-white my-4 py-2 rounded-0 col-12' type={"submit"}>
                                        {
                                            request ? (<div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>) : (
                                                <>
                                                    Let's Talk
                                                </>
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact