import React from 'react';
import { Link,  } from "react-router-dom";
import notfound from './404.jpg'

const NotFound = () => {
  return (
    <div  className="h-100 w-100 text-center bg-white position-relative">
      <img src={notfound} alt="page-not-found" className="img-fluid h-100 " />
      <Link to={'/'} className='position-absolute px-4 d-flex align-items-center' style={{ bottom: 70, left: '50%', transform: 'translateX(-50%)', backgroundColor: '#00B087', textDecoration: 'none', color: '#fff', height: 48 }}>Go To Home</Link>
    </div>
  );
};

export default NotFound;