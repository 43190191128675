import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog5Data } from '../../Data/Blog5Data'

const Blog5 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog5Data} />
    </div>
  )
}

export default Blog5