import React from "react";
import "./ReadMore.css";
const GrowingEntrepreneurialAspirations = ({ contentData }) => {
    return (
        <>
            <div className="read-more-container">
                <div className="read-more-main-container d-flex align-items-center justify-content-center">
                    <div className="header text-center text-white">
                        <h1>{contentData.title}</h1>
                    </div>
                </div>
            </div>
            <div className="read-more-main-container">
                <div className="row mt-5">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="blog-intro">
                            {contentData?.sections.map((sectionKey, index) => {
                                const section = contentData[sectionKey];
                                return (
                                    <div key={index}>
                                        <h2>{section?.heading}</h2>
                                        {section?.description && <p>{section?.description}</p>}
                                        {section?.features &&
                                            section?.features?.map((feature, i) => (
                                                <div key={i}>
                                                    <h6>{feature?.title}:</h6>
                                                    <p>{feature?.description}</p>
                                                </div>
                                            ))}
                                            
                                        {section?.description1 && <p>{section?.description1}</p>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GrowingEntrepreneurialAspirations;
