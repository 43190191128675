import React from 'react'
import './Refund.css'

const Refund = () => {
    return (
        <div className='refund-container px-3'>
            <div className='refund row'>
                <h1 className='text-center'>
                    Refund & Cancellation
                </h1>
                <p> We at <a href='csp.in' style={{textDecoration:"none"}}>www.csp.in</a> strive to provide you with the best possible experience while you avail our services. You can not  cancel any service taken from the platform . It is stated that we do not provide any cancellation for Services as the same deals with government fees that is not refundable and therefore it is not possible for various such reasons.  However the Platform provides retrun/refund for only the following reasons;</p>
                <ul style={{ 'list-style-type': 'square' }} className='text-dark row'>
                    <li>If the service has been purchased but due to a break in the payment gateway and/or payment failure the money has been deducted from the account of the customer and no reverse transaction has occurred into the account of the customer and the platform receives the payment after the cancellation/failure of the transaction then in such scenario return and refund shall be accepted .</li>
                    <li>The Platform shall carry forward its own verification with the respective bank to ascertain the genuiness of the claim once this is done, the refund amount will be credited back into your source account used for purchase of services</li>
                </ul>
                <p>The procedure to return the products are mentioned hereunder;</p>
                <ul style={{ 'list-style-type': 'square' }}>
                    <li>For Return intimation, please email us at <strong>support@csp.in</strong> </li>

                    <li>Once the claim is verified as genuine and reasonable, www.csp.in will initiate refund</li>

                    <li>Refund will be completed within 7-10 business days from the date of cancellation.</li>
                </ul>

                <p>The refund amount will be credited back into your source account, upon completion of order cancellation . Refund will be completed within 7-10 business days.</p>

                <p>The Company at its sole discretion may cancel any order(s):</p>

                <ol>
                    <li>if it suspects a User has undertaken a fraudulent transaction, or</li>
                    <li>if it suspects a User has undertaken a transaction which is not following the Terms of Use, or</li>
                    <li>in case of unavailability of a service, or</li>
                    <li>if the Company does not want to do business with the User</li>
                </ol>

                <p>The Company maintains a negative list of all fraudulent transactions and non-complying Users and reserve the right to deny access to such Users at any time or cancel any orders placed by them in future.</p>


            </div>
        </div>
    )
}

export default Refund