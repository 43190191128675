import React, { Suspense, lazy } from 'react';
import './Knowledge.css';

const LatestBlog = lazy(() => import('./LatestBlog/LatestBlog'));
const BlogCards = lazy(() => import('./BlogCards/BlogCards'));

const Knowledge = () => {
    return (
        <div className="knowledge">
            <Suspense fallback={<div>Loading Latest Blog...</div>}>
                <LatestBlog />
            </Suspense>
            <Suspense fallback={<div>Loading Blog Cards...</div>}>
                <BlogCards />
            </Suspense>
        </div>
    );
};

export default Knowledge;
