import React, { Suspense } from "react";

const CspEgovHome = React.lazy(() => import("./CspEgoHome"));
const CspEgovServices = React.lazy(() => import("./EgovServices/CspEgovServices"));

const CspEgov = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading E-Gov Home...</div>}>
                <CspEgovHome />
            </Suspense>
            <Suspense fallback={<div>Loading E-Gov Services...</div>}>
                <CspEgovServices />
            </Suspense>
        </div>
    );
};

export default CspEgov;
