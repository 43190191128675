import React, { useEffect, useState } from 'react';
import { CustomSvg } from '../../../svg';
import { GetTranlatedValue } from '../../Data/LanguageContext';
import { HomePageData } from '../../Data/HomePageData';
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import '../Home/Home.css'

export const TenthSection = () => {
    const [sequence, setSequence] = useState([0, 1, 2, 5, 4, 3]);
    const [bgColorIndex, setBgColorIndex] = useState(0);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setSequence([0, 1, 2, 3, 4, 5]);
                setIsMobileView(true);
            } else {
                setSequence([0, 1, 2, 5, 4, 3]);
                setIsMobileView(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setBgColorIndex(prevIndex => (prevIndex + 1) % sequence.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [sequence.length]);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "1%",
                },
            },
        ],
    };

    
    return (
        <div className='home_tentsection' style={{ paddingTop: 40 }}>
            <div className='container d-none d-lg-block'>
                <div className='row'>
                    <div className='col-lg-5 col-md-12 col-sm-12 d-flex flex-column justify-content-center tenth-section-content'>
                        <h2 className="fw-bold mb-3" style={{ color: '#16b287', fontSize: 28 }}>
                            {GetTranlatedValue(HomePageData?.TENTHSECTION?.doyouwantheading)}
                        </h2>
                        <ul className='p-0' style={{ listStyleType: 'none' }}>
                            {HomePageData.TENTHSECTION.doyouwant.map((li, i) => (
                                <li key={i} className='d-flex align-items-start mt-3' style={{ color: '#212529', gap: 20 }}>
                                    <div style={{ marginTop: 1 }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg>
                                    </div>
                                    <p className='m-0' style={{ fontWeight: 500, lineHeight: 1.4 }}>{GetTranlatedValue(li)}</p>
                                </li>
                            ))}
                        </ul>
                        <p style={{ textAlign: 'justify' }}>
                            {GetTranlatedValue(HomePageData?.TENTHSECTION?.ifyes)}
                        </p>

                        <strong className=''><p className='dotouwant '> {GetTranlatedValue(HomePageData?.TENTHSECTION?.becomeour)} <span style={{ color: "#16B287" }}> {GetTranlatedValue(HomePageData?.TENTHSECTION?.grahakpratinidhi)}</span></p></strong>
                        <div className='join-now-home'>
                            <a href={`${window.location.origin}/version2/auth/registration`} className="mt-3 btn shadow-none text-white rounded-0 " style={{ width: 130, backgroundColor: '#16b287' }}>
                                {GetTranlatedValue(HomePageData?.TENTHSECTION?.joinnow)}
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12 d-flex justify-content-end'>
                        <div className='py-3'>
                            <div className='row'>
                                {isMobileView ? (
                                    HomePageData.TENTHSECTION.boxlist1.map((li, i) => (
                                        <div key={i} className='col-sm-6' style={{ padding: 12, width:100 }}>
                                            <div
                                                className='position-relative d-flex align-items-center justify-content-center h-100 w-100 p-4  rounded-3'
                                                style={{ backgroundColor: i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9', boxShadow: '0px 3px 6px #00000029', minHeight: 200, transition: 'all 1s linear' }}
                                            >
                                                <p className='m-0' style={{ color: i === sequence[bgColorIndex] ? '#fff' : '#2c5363' }}>{GetTranlatedValue(li)}</p>
                                                <div className='position-absolute rounded-circle d-flex align-items-center justify-content-center' style={{
                                                    height: 40,
                                                    width: 40,
                                                    backgroundColor: i === sequence[bgColorIndex] ? '#f9f9f9' : '#2c5363',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                    transition: 'all 1s linear',
                                                    top: 180
                                                }}>
                                                    {i === 0 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    HomePageData.TENTHSECTION.boxlist.map((li, i) => (
                                        <div key={i} className='col-lg-4 col-md-4 col-sm-4' style={{ padding: 12 }}>
                                            <div
                                                className='position-relative d-flex align-items-center justify-content-center h-100 w-100 p-4 rounded-3'
                                                style={{ backgroundColor: i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9', boxShadow: '0px 3px 6px #00000029', minHeight: 200, transition: 'all 1s linear' }}
                                            >
                                                <p className='m-0' style={{ color: i === sequence[bgColorIndex] ? '#fff' : '#2c5363' }}>{GetTranlatedValue(li)}</p>
                                                <div className='position-absolute rounded-circle d-flex align-items-center justify-content-center' style={{
                                                    height: 40,
                                                    width: 40,
                                                    backgroundColor: i === sequence[bgColorIndex] ? '#f9f9f9' : '#2c5363',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                    transition: 'all 1s linear',
                                                    top: 180
                                                }}>
                                                    {
                                                        i === 0 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                    }
                                                    {
                                                        i === 1 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                    }
                                                    {
                                                        i === 2 && <CustomSvg.DownArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                    }
                                                    {
                                                        i === 3 && <CustomSvg.Thumb color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                    }
                                                    {
                                                        i === 4 && <CustomSvg.LeftArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#f9f9f9'} />
                                                    }
                                                    {
                                                        i === 5 && <CustomSvg.LeftArrow color={i === sequence[bgColorIndex] ? '#2c5363' : '#fff'} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container d-block d-lg-none'>
                <div className='row'>
                    <div className='col-12 d-flex flex-column justify-content-center mobile-section-content'>
                        <div className='col-lg-5 col-md-12 col-sm-12 d-flex flex-column justify-content-center tenth-section-content'>
                            <h2 className="fw-bold mb-3" style={{ color: '#16b287', fontSize: 28 }}>
                                {GetTranlatedValue(HomePageData?.TENTHSECTION?.doyouwantheading)}
                            </h2>
                            <ul className='p-0' style={{ listStyleType: 'none' }}>
                                {HomePageData.TENTHSECTION.doyouwant.map((li, i) => (
                                    <li key={i} className='d-flex align-items-center mt-3' style={{ color: '#212529', gap: 20 }}>
                                        <div style={{ marginTop: 1 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                            </svg>
                                        </div>
                                        <p className='m-0' style={{ fontWeight: 500, lineHeight: 1.4 }}>{GetTranlatedValue(li)}</p>
                                    </li>
                                ))}
                            </ul>
                            <p style={{ textAlign: 'justify' }}>
                                {GetTranlatedValue(HomePageData?.TENTHSECTION?.ifyes)}
                            </p>

                            <strong className=''><p className='dotouwant '> {GetTranlatedValue(HomePageData?.TENTHSECTION?.becomeour)} <span style={{ color: "#16B287" }}> {GetTranlatedValue(HomePageData?.TENTHSECTION?.grahakpratinidhi)}</span></p></strong>
                            <div className='join-now-home'>
                                <a href={`https://uat.csp.in/version2/auth/registration`} className="mt-3 btn shadow-none text-white rounded-0 " style={{ width: 130, backgroundColor: '#16b287' }}>
                                    {GetTranlatedValue(HomePageData?.TENTHSECTION?.joinnow)}
                                </a>
                            </div>
                        </div>
                        <div className='py-5 mb-3 px-3'>
                            <div className='py-4'>
                                <Slider {...settings}>
                                    {HomePageData.TENTHSECTION.boxlist.map((li, i) => (
                                        <div key={i} className="col-lg-4 col-md-4 col-sm-4 pb-4" style={{ padding: 12, }}>
                                            <div
                                                className="position-relative d-flex justify-content-center h-100 p-3 rounded-3"
                                                style={{
                                                    backgroundColor: "#f9f9f9",
                                                    width:"90%",
                                                    minHeight: 200,
                                                    transition: "all 1s linear",
                                                }}
                                            >
                                                <p
                                                    className="m-0"
                                                    style={{ color:"#2c5363", fontSize:15 }}
                                                >
                                                    {GetTranlatedValue(li)}
                                                </p>
                                                <div
                                                    className="position-absolute rounded-circle d-flex align-items-center justify-content-center"
                                                    style={{
                                                        height: 40,
                                                        width: 40,
                                                        backgroundColor:"#2c5363",
                                                        left: "50%",
                                                        transform: "translateX(-50%)",
                                                        transition: "all 1s linear",
                                                        top: 140,
                                                    }}
                                                >
                                                    {i === 0 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? "#2c5363" : "#f9f9f9"} />}
                                                    {i === 1 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? "#2c5363" : "#f9f9f9"} />}
                                                    {i === 2 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? "#2c5363" : "#f9f9f9"} />}
                                                    {i === 3 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? "#2c5363" : "#f9f9f9"} />}
                                                    {i === 4 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? "#2c5363" : "#f9f9f9"} />}
                                                    {i === 5 && <CustomSvg.RightArrow color={i === sequence[bgColorIndex] ? "#2c5363" : "#fff"} />}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
