import React, { Suspense, lazy } from 'react';

const AboutHome = lazy(() => import('./AboutHome/AboutHome'));
const Enquiry = lazy(() => import('./Enquiry/Enquiry'));
const MissionVision = lazy(() => import('./Mission-Vision/Mission-Vision'));
const Values = lazy(() => import('./Values/Values'));

const About = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading About Home...</div>}>
                <AboutHome />
            </Suspense>
            <Suspense fallback={<div>Loading Mission & Vision...</div>}>
                <MissionVision />
            </Suspense>
            <Suspense fallback={<div>Loading Values...</div>}>
                <Values />
            </Suspense>
            <Suspense fallback={<div>Loading Enquiry...</div>}>
                <Enquiry />
            </Suspense>
        </div>
    );
};

export default About;
