import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog4Data } from '../../Data/Blog4Data'

const Blog4 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog4Data} />
    </div>
  )
}

export default Blog4