import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog1Data } from '../../Data/Blog1Data'

const Blog1 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog1Data} />
    </div>
  )
}

export default Blog1