import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { AepsBlogData } from '../../Data/AepsBlogData'

const InnerBlog = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={AepsBlogData} />
    </div>
  )
}

export default InnerBlog