import React, { Suspense } from "react";

const FaqHome = React.lazy(() => import("./FaqHome"));
const FaqQue = React.lazy(() => import("./FaqQue/FaqQue"));

const Faq = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading FAQ Home...</div>}>
                <FaqHome />
            </Suspense>
            <Suspense fallback={<div>Loading FAQ Questions...</div>}>
                <FaqQue />
            </Suspense>
        </div>
    );
};

export default Faq;
