import React, { useMemo } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Email from './email.png';
import Call from './call.png';
import Home from './location-pin.png';
import Expand from './down.png';
import { GetTranlatedValue } from '../Data/LanguageContext';
import { FooterServicePageData } from '../Data/FooterData';

const Footer = () => {
    const ToTop = () => {
        window.scrollTo(0, 0);
    };

    // Memoize repeated sections to avoid re-rendering
    const companyLinks = useMemo(() => (
        <>
            <li>
                <Link to='/About' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.aboutus)}</Link>
            </li>
            <li>
                <Link to='/Careers' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.career)}</Link>
            </li>
            <li>
                <Link to='/Resources' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.resources)}</Link>
            </li>
            <li>
                <Link to='/Become-a-Partner' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.join)}</Link>
            </li>
            <li>
                <Link to='/ContactUs' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.contact)}</Link>
            </li>
        </>
    ), []);

    const servicesLinks = useMemo(() => (
        <>
            <li><a href='/banking'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Banking)}</a></li>
            <li><a href='/payment'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Payment)}</a></li>
            <li><a href='/financial'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Financial)}</a></li>
            <li><a href='/investment'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Investment)}</a></li>
            <li><a href='/travel'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Travel)}</a></li>
        </>
    ), []);

    const importantLinks = useMemo(() => (
        <>
            <li>
                <Link to='./privacypolicy' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.PrivacyPolicy)}</Link>
            </li>
            <li>
                <Link to='./terms&conditions' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.TandC)}</Link>
            </li>
            <li>
                <Link to='./cookiepolicy' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Cookie)}</Link>
            </li>
            <li>
                <Link to='./refund&cancellation' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.RefundCancellation)}</Link>
            </li>
            <li>
                <Link to='./desclaimer' onClick={ToTop}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Desclaimer)}</Link>
            </li>
        </>
    ), []);

    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='row position-relative'>
                    {/* Company Links Section */}
                    <div className='col-lg-3 col-md-3 py-1'>
                        <div className='large-device-footer'>
                            <h5 className='footer-head pb-3'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.company)}</h5>
                            <ul className='p-0'>{companyLinks}</ul>
                        </div>
                        <div className='small-devices-footer'>
                            <div className='pb-1 d-flex justify-content-between align-items-center' data-bs-toggle="collapse" href="#companyCollapse" role="button" aria-expanded="false" aria-controls="companyCollapse">
                                <h5 className='footer-head m-0'>Company</h5>
                                <img className='expand-icon' src={Expand} alt='expand' />
                            </div>
                            <div className='collapse' id="companyCollapse">
                                <ul className='p-0'>{companyLinks}</ul>
                            </div>
                        </div>
                    </div>

                    {/* Services Links Section */}
                    <div className='col-lg-3 col-md-3 py-1'>
                        <div className='large-device-footer'>
                            <h5 className='footer-head pb-3'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.services)}</h5>
                            <ul className='p-0'>{servicesLinks}</ul>
                        </div>
                        <div className='small-devices-footer'>
                            <div className='pb-1 d-flex align-items-center justify-content-between' data-bs-toggle="collapse" href="#solutionCollapse" role="button" aria-expanded="false" aria-controls="solutionCollapse">
                                <h5 className='footer-head m-0'>Services</h5>
                                <img className='expand-icon' src={Expand} alt='expand' />
                            </div>
                            <div className='collapse' id="solutionCollapse">
                                <ul className='p-0'>{servicesLinks}</ul>
                            </div>
                        </div>
                    </div>

                    {/* Important Links Section */}
                    <div className='col-lg-3 col-md-3 py-1'>
                        <div className='large-device-footer'>
                            <h5 className='footer-head pb-3'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.importtantlink)}</h5>
                            <ul className='p-0'>{importantLinks}</ul>
                        </div>
                        <div className='small-devices-footer'>
                            <div className='pb-3 d-flex justify-content-between align-items-center' data-bs-toggle="collapse" href="#impLinksCollapse" role="button" aria-expanded="false" aria-controls="impLinksCollapse">
                                <h5 className='footer-head m-0'>Important Links</h5>
                                <img className='expand-icon' src={Expand} alt='expand' />
                            </div>
                            <div className='collapse' id="impLinksCollapse">
                                <ul className='p-0'>{importantLinks}</ul>
                            </div>
                        </div>
                    </div>

                    {/* Reach Us Section */}
                    <div className='col-lg-3 col-md-3 py-1'>
                        <h5 className='footer-head pb-3'>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.ReachUs)}</h5>
                        <div className='reach'>
                            <ul className='p-0'>
                                <li className='d-flex'>
                                    <div className='px-2'><img src={Home} style={{width:20, height:20}} alt='address' /></div>
                                    <p style={{color:"#fff"}}>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Address)}</p>
                                </li>
                                <li className='d-flex align-items-center'>
                                    <div className='py-2 px-2'><img src={Call} style={{width:20, height:20}} alt='call' /></div>
                                    <a href='tel:7314555555'>+91 7314555555</a>
                                </li>
                                <li className='d-flex align-items-center'>
                                    <div className='py-2 px-2'><img src={Email} style={{width:20, height:20}} alt='email' /></div>
                                    <a href='mailto:support@csp.in'>support@csp.in</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Bottom Footer */}
                <div className='bottom-footer py-3 mt-1 d-flex justify-content-center align-items-center'>
                    {GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.mainDesclaimer)}
                    <br />
                    {GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.terms)}
                    <br />
                    {GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.condition)}
                </div>
            </div>

            {/* Footer Copyright */}
            <div className='footer-copyright'>
                <div className='footer-container align-items-center'>
                    <div className='large-device-footer-copyright'>
                        <div className='d-flex justify-content-between'>
                            <span>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.Copyright)}&nbsp;<i className='fa fa-copyright'></i>&nbsp;{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.rights)}</span>
                            <span>{GetTranlatedValue(FooterServicePageData?.FOOTERSERVICES?.startup)}</span>
                        </div>
                    </div>
                    <div className='small-device-footer-copyright'>
                        <span>Copyright&nbsp;<i className='fa fa-copyright'></i>&nbsp;2023 CSP INDIA NETWORK PRIVATE LIMITED. All Rights Reserved.</span>
                        <span>A Startup India Certified Company.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Footer);
