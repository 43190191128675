import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog9Data } from '../../Data/Blog9Data'

const Blog9 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog9Data} />
    </div>
  )
}

export default Blog9