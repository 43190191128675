import React, { Suspense } from "react";

const CspBankingHome = React.lazy(() => import("./CspBankingHome"));
const CspBankingServices = React.lazy(() => import("./CspBankingServices/CspBankingservices"));

const CspBanking = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading Home...</div>}>
                <CspBankingHome />
            </Suspense>
            <Suspense fallback={<div>Loading Services...</div>}>
                <CspBankingServices />
            </Suspense>
        </div>
    );
};

export default CspBanking;
