import React, { Suspense } from "react";

const CspFinancialHome = React.lazy(() => import("./CspFinancialhome"));
const CspFinancialServices = React.lazy(() => import("./CspfinanicalServices/CspFinancialservices"));

const CspFinanical = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading Home...</div>}>
                <CspFinancialHome />
            </Suspense>
            <Suspense fallback={<div>Loading Services...</div>}>
                <CspFinancialServices />
            </Suspense>
        </div>
    );
};

export default CspFinanical;
