import { HomePageData } from "../../Data/HomePageData"
import { GetTranlatedValue } from "../../Data/LanguageContext"

export const SevenSection = () => {
    return (
        <div className="container" style={{ paddingTop: 80, paddingBottom: 20 }}>
            <div className='row'>
                <div className='col-lg-9 mx-auto'>
                    <h2 className=' text-center mb-4' style={{ fontWeight: 700, color: '#3c5363' }}>
                        {GetTranlatedValue(HomePageData?.SEVENTHSECTION?.seventhheading)}
                    </h2>
                    <p className='text-center mb-5'>
                        {GetTranlatedValue(HomePageData?.SEVENTHSECTION?.seventhtext)}
                    </p>
                </div>
            </div>
        </div>
    )
}