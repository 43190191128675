import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog3Data } from '../../Data/Blog3Data'

const Blog3 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog3Data} />
    </div>
  )
}

export default Blog3