import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog7Data } from '../../Data/Blog7Data'

const Blog7 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog7Data} />
    </div>
  )
}

export default Blog7