import React, { forwardRef } from 'react';
import CSPTitle from '../Home/Assets/tagline (2).png';
import CSPTitleMobile from '../Home/Assets/mobile-life-csp (2).png';
import { HomePageData } from '../../Data/HomePageData';
import { GetTranlatedValue } from '../../Data/LanguageContext';

export const ThirdSection = forwardRef((props, ref) => {

    return (
        <div className="bg-light" ref={ref}>
            <div className="container cspin-growth">
                <div className="text-center">
                    <div className='text-center d-flex  justify-content-center'>
                        <img src={CSPTitle} className='img-fluid cspin-incomefix d-none d-md-block' alt='cspin' />
                    </div>
                    <div className='text-center d-flex  justify-content-center'>
                        <img src={CSPTitleMobile} className='img-fluid cspin-incomefix d-block d-md-none' alt='cspin' />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-10 col-md-10 col-sm-12 mx-auto csp-info">
                       <p>{GetTranlatedValue(HomePageData?.OFFERINGSECTION?.offerparagraph)}<span style={{fontWeight:600}}>{GetTranlatedValue(HomePageData?.OFFERINGSECTION?.offerparagraph1)}</span></p>                 
                    </div>
                </div>
            </div>
        </div>
    );
});



