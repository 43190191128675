import React, { Suspense } from "react";

const CspGstHome = React.lazy(() => import("./CspGstHome.js"));
const CspGstServices = React.lazy(() => import("./CspGstServices/CspGstservices"));

const CspGstService = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading Home...</div>}>
                <CspGstHome />
            </Suspense>
            <Suspense fallback={<div>Loading Services...</div>}>
                <CspGstServices />
            </Suspense>
        </div>
    );
};

export default CspGstService;
