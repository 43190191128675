import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog8Data } from '../../Data/Blog8Data'

const Blog8 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog8Data} />
    </div>
  )
}

export default Blog8