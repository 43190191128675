import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Landing/Home/Home';
import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import Careers from './Components/Careers/Careers';
import Knowledge from './Components/Knowledge/Knowledge';
import Contact from './Components/Contact/Contact';
import Privacy from './Components/Privacy/Privacy';
import Terms from './Components/Terms/Terms';
import Cookie from './Components/Cookie/Cookie';
import Refund from './Components/Refund/Refund';
import Desclaimer from './Components/Desclaimer/Desclaimer';
import BecomePartner from './Components/BecomePartner/BecomePartner';
import CspBanking from './Components/CspServices/Banking/CspBanking';
import CspPayment from './Components/CspServices/Payment/CspPayment';
import CspFinanical from './Components/CspServices/Financial/Cspfinanical';
import CspInvestment from './Components/CspServices/Investment/CspInvestment';
import CspTravel from './Components/CspServices/Travel/CspTravel';
import CspEgov from './Components/CspServices/Egov/CspEgov';
import Faq from './Components/CspServices/Faq/Faq';
import NotFound from './Components/NotFound/Notfound';
import { GetFontValue } from './Components/Data/LanguageContext';
import InnerBlog from './Components/Knowledge/ReadMore/InnerBlog';
import Blog1 from './Components/Knowledge/ReadMore/Blog1';
import Blog2 from './Components/Knowledge/ReadMore/Blog2';
import Blog3 from './Components/Knowledge/ReadMore/Blog3';
import Blog4 from './Components/Knowledge/ReadMore/Blog4';
import Blog9 from './Components/Knowledge/ReadMore/Blog9';
import Blog5 from './Components/Knowledge/ReadMore/Blog5';
import Blog6 from './Components/Knowledge/ReadMore/Blog6';
import Blog7 from './Components/Knowledge/ReadMore/Blog7';
import Blog8 from './Components/Knowledge/ReadMore/Blog8';
import CspGstService from './Components/CspServices/Gst/CspGstService';
import CspLegalService from './Components/CspServices/Legal/CspLegalService';


function App() {
    const is404Page = () => window.location.pathname === "/404";
    let fontstyle = GetFontValue()
    return (
        <>
           
            <div className='App' style={{ fontFamily: fontstyle }}>
                {!is404Page() && <Navbar />}
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/About' element={<About />} />
                    <Route path='/Careers' element={<Careers />} />
                    <Route path='/Resources' element={<Knowledge />} />
                    <Route path='/blogs' element={<InnerBlog />} />
                    <Route path='/blogs_1' element={<Blog1 />} />
                    <Route path='/blogs_2' element={<Blog2 />} />
                    <Route path='/blogs_3' element={<Blog3 />} />
                    <Route path='/blogs_4' element={<Blog4 />} />
                    <Route path='/blogs_9' element={<Blog9 />} />
                    <Route path='/blogs_5' element={<Blog5 />} />
                    <Route path='/blogs_6' element={<Blog6 />} />
                    <Route path='/blogs_7' element={<Blog7 />} />
                    <Route path='/blogs_8' element={<Blog8 />} />


                    <Route path='/Become-a-Partner' element={<BecomePartner />} />
                    <Route path='/ContactUs' element={<Contact />} />
                    <Route path='/privacypolicy' element={<Privacy />} />
                    <Route path='/terms&conditions' element={<Terms />} />
                    <Route path='/cookiepolicy' element={<Cookie />} />
                    <Route path='/refund&cancellation' element={<Refund />} />
                    <Route path='/desclaimer' element={<Desclaimer />} />


                    <Route path='/banking' element={<CspBanking />} />
                    <Route path='/payment' element={<CspPayment />} />
                    <Route path='/financial' element={<CspFinanical />} />
                    <Route path='/investment' element={<CspInvestment />} />
                    <Route path='/travel' element={<CspTravel />} />
                    <Route path='/egovernance' element={<CspEgov />} />
                    <Route path='/gst&taxation' element={<CspGstService />} />
                    <Route path='/legal' element={<CspLegalService />} />
                    <Route path='/faq' element={<Faq />} />
                    <Route path="/404" element={<NotFound />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
                <Footer />
            </div>
        </>
    );
}

export default App;
