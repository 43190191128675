import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog6Data } from '../../Data/Blog6Data'

const Blog6 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog6Data} />
    </div>
  )
}

export default Blog6