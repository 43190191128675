import React from 'react'
import './Careers.css'
const Careers = () => {
    return (
        <>
            <div className='career-container'>
                <div className='career-main-container'>
                    <div className='open-pos text-white'>
                        <div className='d-flex flex-column justify-content-center align-items-center position-relative search'>
                            <h2 className='py-2'>DO YOU HAVE IT IN YOU?</h2>
                            <p className='text-center'>
                                Your Dream Team is calling you
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='open-positions-text text-center  mx-auto'>
                <p>Looking for a career, collaborative team and unlimited opportunities to grow? You’ve come to the right place.</p>
                <p>
                    At CSP, you’ll be part of a team that values expert insights, bold ideas and intellectual courage. You will find your place in an environment built on strong relationships where every associate is empowered to make an impact and is valued for their contributions.
                </p>
                <p>
                    We invite you to mail us your Resume at <a href='mailto:hr@csp.in'>hr@csp.in</a>
                </p>
            </div>
        </>
    )
}

export default Careers