import React, { Suspense } from "react";

const CspInvestmentHome = React.lazy(() => import("./CspInvestmentHome"));
const CspInvestmentServices = React.lazy(() => import("./CspInvestmentServices/CspInvestmentServices."));

const CspInvestment = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading Home...</div>}>
                <CspInvestmentHome />
            </Suspense>
            <Suspense fallback={<div>Loading Services...</div>}>
                <CspInvestmentServices />
            </Suspense>
        </div>
    );
};

export default CspInvestment;
