import React, { Suspense } from "react";

const CspPaymentHome = React.lazy(() => import("./CspPaymenthome"));
const CspPaymentServices = React.lazy(() => import("./CspPaymentServices/CspPaymentService"));

const CspPayment = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading Home...</div>}>
                <CspPaymentHome />
            </Suspense>
            <Suspense fallback={<div>Loading Services...</div>}>
                <CspPaymentServices />
            </Suspense>
        </div>
    );
};

export default CspPayment;
