import './Navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../Assets/icons/cspLogo (1).png';
import BankingIcon from './Assets/banking.png';
import Payment from './Assets/bill-payment.png';
import Finance from './Assets/financial.png';
import Investment from './Assets/investment.png';
import Travel from './Assets/travels (1).png';
import Egov from './Assets/government.png';
import blog from './Assets/blog.svg';
import faq from './Assets/faq.svg';
import { useState } from 'react';
import { GetTranlatedValue, LANGUAGES } from '../Data/LanguageContext';
import { NavbarPageData } from '../Data/NavbarData';

const Navbar = () => {
    const [setSelectedLang] = useState(localStorage.getItem("language"));
    const [isNavOpen, setIsNavOpen] = useState(false);
    const route = useNavigate();

    const ToTop = () => {
        window.scrollTo(0, 0);
    }

    const serviceDropdown = [
        { title: 'Banking', subtitle: 'Service your Locality', icon: BankingIcon, uri: '/banking' },
        { title: 'Payment', subtitle: 'Utility and Bill Payments', icon: Payment, uri: '/payment' },
        { title: 'Financial', subtitle: 'Multiple Loan Options', icon: Finance, uri: '/financial' },
        { title: 'Investment', subtitle: 'Prudent Saving Options', icon: Investment, uri: '/investment' },
        { title: 'Travel', subtitle: 'Train and Bus Ticket', icon: Travel, uri: '/travel' },
        { title: 'E-Governance', subtitle: 'Digital Signature,Govt Schemes info etc.', icon: Egov, uri: '/egovernance' },
        { title: 'Gst & Taxation', subtitle: 'Assistance in Filling', icon: Egov, uri: '/gst&taxation' },
        { title: 'Legal', subtitle: 'legal Docunmentation', icon: Egov, uri: '/legal' },
    ];

    const ResourcesDropdown = [
        { title: 'Blog', subtitle: 'Publish articles online.', icon: blog, uri: '/Resources' },
        { title: 'FAQ', subtitle: 'Clear Your Douts', icon: faq, uri: '/faq' },
    ];

    const selectLanguage = (lang) => {
        localStorage.setItem("language", lang);
        setSelectedLang(lang);
        route(0);
    }

    const handleLinkClick = () => {
        const navbarCollapse = document.getElementById('navBar');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
            setIsNavOpen(false);
        }
    }

    const toggleNavbar = () => {
        setIsNavOpen(!isNavOpen);
    }

    return (
        <div className='navbar-container' id="TOPONE">
            <nav className="navbar navbar-expand-lg shadow-sm fixed-top">
                <div className="container">
                    <Link className="navbar-brand align-items-center" to="/" onClick={() => { ToTop(); handleLinkClick(); }}>
                        <img src={Logo} alt="csp" className='logo-img' style={{ height: 51, width: 96 }} />
                    </Link>
                    <button className="navbar-toggler show" type="button" data-bs-toggle="collapse" data-bs-target={"#navBar"} aria-controls="navBar"
                        aria-expanded={isNavOpen} aria-label="Toggle navigation" onClick={toggleNavbar} style={{
                            marginRight: "1.1rem"
                        }}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse" + (isNavOpen ? " show" : "")} id={"navBar"}>
                        <ul className="navbar-nav ms-auto" id='navLinks'>
                            <li className="nav-item home-nav-item active">
                                <Link className="nav-link" aria-current="page" to="/" onClick={handleLinkClick}>Home</Link>
                            </li>
                            <li className="nav-item service-nav-item dropdown">
                                <Link className="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.services)}
                                </Link>
                                <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown" style={{ width: 300 }}>
                                    {serviceDropdown.map((drop) => (
                                        <li className='py-1' key={drop.title}>
                                            <Link className="dropdown-item white-space-unset d-flex align-items-center" to={drop.uri} onClick={handleLinkClick}>
                                                <div className="d-flex align-items-start me-3">
                                                    <div>
                                                        <img className="" src={drop.icon} alt={drop.title} />
                                                    </div>
                                                </div>
                                                <span className="align-middle">
                                                    <h5 className="fw-500 mb-1" style={{ fontSize: 17, color: '#212529' }}>{drop.title}</h5>
                                                    <p className="mb-0 d-block text-wrap" style={{ fontSize: 12, color: '#212529', lineHeight: 1.3 }}>{drop.subtitle}</p>
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/About" onClick={() => { ToTop(); handleLinkClick(); }}>
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.aboutus)}
                                </Link>
                            </li>
                            <li className="nav-item service-nav-item dropdown">
                                <Link className="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.resources)}
                                </Link>
                                <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown" style={{ width: 300 }}>
                                    {ResourcesDropdown.map((Resourcesdrop) => (
                                        <li key={Resourcesdrop.title}>
                                            <Link className="dropdown-item white-space-unset d-flex align-items-center" to={Resourcesdrop.uri} onClick={handleLinkClick}>
                                                <div className="d-flex align-items-start me-3">
                                                    <div>
                                                        <img className="" src={Resourcesdrop.icon} alt={Resourcesdrop.title} />
                                                    </div>
                                                </div>
                                                <span className="align-middle">
                                                    <h5 className="fw-500 mb-1" style={{ fontSize: 17, color: '#212529' }}>{Resourcesdrop.title}</h5>
                                                    <p className="mb-0 d-block text-wrap" style={{ fontSize: 12, color: '#212529', lineHeight: 1.3 }}>{Resourcesdrop.subtitle}</p>
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ContactUs" onClick={() => { ToTop(); handleLinkClick(); }}>
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.contact)}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={`${window.location.origin}/version2/auth/login`} onClick={handleLinkClick}>
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.login)}
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/"
                                    id="languageDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.Language)}
                                </a>
                                <ul className="dropdown-menu p-0" aria-labelledby="languageDropdown">
                                    <li onClick={() => selectLanguage(LANGUAGES.ENGLISH)}>
                                        <a href="/" className="dropdown-item">English</a>
                                    </li>
                                    <li onClick={() => selectLanguage(LANGUAGES.HINDI)}>
                                        <a href="/" className="dropdown-item">हिन्दी</a>
                                    </li>
                                    <li onClick={() => selectLanguage(LANGUAGES.MARATHI)}>
                                        <a href="/" className="dropdown-item">मराठी</a>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                        <div className="d-flex mobile-view-btns">
                            <a href={`${window.location.origin}/version2/auth/registration`} className="btn log-btn rounded-0" onClick={handleLinkClick}>
                                {GetTranlatedValue(NavbarPageData?.NAVBARSERVICES?.joinnow)}
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
