import React, { Suspense } from "react";

const CspLegalHome = React.lazy(() => import("./CspLegalHome"));
const CspLegalServices = React.lazy(() => import("./CspLegalServices/CspLegalservices.js"));

const CspLegalService = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading Home...</div>}>
                <CspLegalHome />
            </Suspense>
            <Suspense fallback={<div>Loading Services...</div>}>
                <CspLegalServices />
            </Suspense>
        </div>
    );
};

export default CspLegalService;
