import React from 'react'
import GrowingEntrepreneurialAspirations from './GrowingEntrepreneurialAspirations'
import { Blog2Data } from '../../Data/Blog2Data'

const Blog2 = () => {
  return (
    <div>
      <GrowingEntrepreneurialAspirations contentData={Blog2Data} />
    </div>
  )
}

export default Blog2