import React, { Suspense } from "react";

const CspTravelhome = React.lazy(() => import("./CspTravelHome"));
const CspTravelServices = React.lazy(() => import("./CspTravelServices/CspTravelServices"));

const CspTravel = () => {
    return (
        <div className="about">
            <Suspense fallback={<div>Loading Travel Home...</div>}>
                <CspTravelhome />
            </Suspense>
            <Suspense fallback={<div>Loading Travel Services...</div>}>
                <CspTravelServices />
            </Suspense>
        </div>
    );
};

export default CspTravel;
